import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";

import "./_share-buttons.scss";

const ShareButtons = ({ url }) => {
  return (
    <div className="share-buttons">
      <FacebookShareButton url={url}>
        <FacebookIcon size={36} round />
      </FacebookShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon size={36} round />
      </LinkedinShareButton>
      <WhatsappShareButton url={url}>
        <WhatsappIcon size={36} round />
      </WhatsappShareButton>
      <EmailShareButton url={url}>
        <EmailIcon size={36} round />
      </EmailShareButton>
    </div>
  );
};

export default ShareButtons;
