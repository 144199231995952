import React from "react";
import { graphql } from "gatsby";

import { mainNavigation, homesNavigation } from "src/data";
import Header from "components/Header";
import SEO from "components/SEO";
import Banner from "components/Banner";
import Layout from "components/Layout";
import Footer from "components/Footer";
import RichTextRenderer from "components/RichTextRenderer";
import ShareButtons from "components/ShareButtons";
import CookieBar from "components/CookieBar";

import "./_blog-post.scss";

const BlogPostTemplate = props => {
  const { logo } = props.data;
  const { siteUrl } = props.data.site.siteMetadata;
  const {
    title,
    slug,
    body,
    image,
    seoTitle,
    seoDescription,
    canonical,
  } = props.data.contentfulBlogPost;
  const SEOtitle = seoTitle ? seoTitle : title;
  const pathName = canonical ? canonical : slug;
  return (
    <>
      <SEO
        title={SEOtitle}
        description={seoDescription}
        image={image.file.url}
        pathname={pathName}
      />
      <Header
        main={mainNavigation}
        homes={homesNavigation}
        logo={logo.childImageSharp.fluid}
        logoAlt="Moodu moduliniai namai logotipas"
      />
      <Banner heading={title} isInnerPage isCentered imageData={image.fluid} />
      <Layout>
        <div className="blog-post__layout">
          <RichTextRenderer richTextJson={body.json} />
          <ShareButtons url={`${siteUrl}/blogas/${slug}`} />
        </div>
      </Layout>
      <Footer logo={logo.childImageSharp.fluid} />
      <CookieBar />
    </>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl: url
      }
    }
    logo: file(relativePath: { eq: "logo-top.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      image {
        file {
          url
        }
        title
        fluid(maxWidth: 1640) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      body {
        json
      }
      seoTitle
      seoDescription
    }
  }
`;
